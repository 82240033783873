import Events from 'events';
import assign from 'object-assign';

import AppDispatcher from '../dispatcher';
import DepartmentsConstants from '../constants/DepartmentsConstants';
import APIutils from '../utils/APIutils';

let EventEmitter = Events.EventEmitter;
let CHANGE_EVENT = 'change';
var _departments = [];

function getDepartments(user) {
  return APIutils.getDepartments(user).then(function (data) {
    _departments = data.response;
  });
}

let DepartmentsStore = assign({}, EventEmitter.prototype, {
  /**
   * @return {object}
   */
  getDepartments: function () {
    return _departments;
  },

  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback);
  },

  /**
   * @param {function} callback
   */
  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
});

AppDispatcher.register(function (action) {
  switch (action.actionType) {
    case DepartmentsConstants.GET_DEPARTMENTS:
      getDepartments(action.user).then(function () {
        DepartmentsStore.emitChange();
      });
      break;
    default:
    // no op
  }
});

export default DepartmentsStore;
