/*
require("es6-promise").polyfil();
var Promise = require("es6-promise").Promise;
*/
import UserActions from '../actions/UserActions';

let APIutils = {
  getBaseUrl() {
    return process.env.REACT_APP_BASE_URL;
  },
  loginSSO(token) {
    return callApi({
      url: APIutils.getBaseUrl() + '/oauth2/login',
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: token,
      },
    });
  },
  login(username, password) {
    return callApi({
      url: APIutils.getBaseUrl() + '/login',
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      data: {
        username,
        password,
      },
    });
  },
  logout(user) {
    return callApi({
      url: APIutils.getBaseUrl() + '/logout',
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      data: {
        username: user.username,
        token: user.token,
      },
    });
  },
  recovery(username) {
    return callApi({
      url: APIutils.getBaseUrl() + '/recovery',
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      data: {
        username: username,
      },
    });
  },
  getTools(user) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/tools',
      method: 'GET',
      headers: {
        Authorization: user.accessToken,
      },
    });
  },
  getDepartments(user) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/departments',
      headers: {
        Authorization: user.accessToken,
      },
    });
  },
  getToolDetails(user, toolId) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/tool/' + toolId,
      headers: {
        Authorization: user.accessToken,
      },
    });
  },
  setTheft(user, toolDetailsId, doc) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/tool/' + toolDetailsId + '/theft',
      method: 'POST',
      headers: {
        Authorization: user.accessToken,
      },
      data: doc,
      withFile: true,
    });
  },
  setLost(user, toolDetailsId, doc) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/tool/' + toolDetailsId + '/loss',
      method: 'POST',
      headers: {
        Authorization: user.accessToken,
      },
      data: doc,
      withFile: true,
    });
  },
  setDamaged(user, toolDetailsId, doc) {
    return callApi({
      url:
        APIutils.getBaseUrl() + '/services/tool/' + toolDetailsId + '/damage',
      method: 'POST',
      headers: {
        Authorization: user.accessToken,
      },
      data: doc,
      withFile: true,
    });
  },
  setAccept(user, toolDetailsId) {
    return callApi({
      url:
        APIutils.getBaseUrl() + '/services/tool/' + toolDetailsId + '/accept',
      method: 'POST',
      headers: {
        Authorization: user.accessToken,
        'Content-type': 'application/json',
      },
      data: {},
    });
  },
  setPolicyAccept(user, toolDetailsId) {
    return callApi({
      url:
        APIutils.getBaseUrl() +
        '/services/tool/' +
        toolDetailsId +
        '/policy/accept',
      method: 'POST',
      headers: {
        Authorization: user.accessToken,
        'Content-type': 'application/json',
      },
      data: {},
    });
  },
  sendExpirationMail(username) {
    return callApi({
      url: APIutils.getBaseUrl() + '/accountexpired',
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      data: {
        username: username,
      },
    });
  },
  getDocuments(user) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/document',
      method: 'GET',
      headers: {
        Authorization: user.accessToken,
      },
    });
  },
  getDocumentDetails(user, documentId) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/document/' + documentId,
      headers: {
        Authorization: user.accessToken,
      },
    });
  },
  setDocumentAccepted(user, documentId) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/document/' + documentId,
      method: 'POST',
      headers: {
        Authorization: user.accessToken,
        'Content-type': 'application/json',
      },
      data: {},
    });
  },
  setDisclaimerAccepted(user, documentId, agreed) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/document/' + documentId,
      method: 'POST',
      headers: {
        Authorization: user.accessToken,
        'Content-type': 'application/json',
      },
      data: {agreed: agreed},
    });
  },
  setConflictOfInterestAccepted(
    user,
    documentId,
    conflict,
    text1,
    text2,
    text3,
  ) {
    return callApi({
      url: APIutils.getBaseUrl() + '/services/document/' + documentId,
      method: 'POST',
      headers: {
        Authorization: user.accessToken,
        'Content-type': 'application/json',
      },
      data: {
        isConflict: conflict,
        text1,
        text2,
        text3,
      },
    });
  },
};

function callApi(params) {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      let response = '';
      try {
        if (xhr.readyState === 4) {
          switch (xhr.status) {
            case 200:
            case 201:
              if (xhr.responseText.length) {
                response = JSON.parse(xhr.responseText);
              }
            case 204:
              resolve({status: xhr.status, response});
              break;
            case 400:
            case 404:
              if (xhr.responseText.length) {
                response = JSON.parse(xhr.responseText);
              }
            case 403:
              // Token expired
              UserActions.logout();
            case 500:
            default:
              reject({status: xhr.status, response});
              break;
          }
        }
      } catch (e) {
        reject(500, response);
      }
    };

    xhr.ontimeout = function () {
      reject(500, '');
    };

    xhr.open(params.method || 'GET', params.url);

    // let data = [];
    // for(let d in params.data) {
    // 	data.push(d + "=" + params.data[d]);
    // }
    // data = data.join("&");

    params.headers = params.headers || {};
    params.headers['X-Requested-With'] = 'XMLHttpRequest';

    // if(params.method === "POST") {

    // 	// params.headers["Content-length"] = data.length;
    // 	// params.headers["Connection"] = "close";
    // }

    for (let header in params.headers) {
      xhr.setRequestHeader(header, params.headers[header]);
    }
    if (params.withFile) {
      xhr.send(params.data);
    } else xhr.send(JSON.stringify(params.data));
  });
}

export default APIutils;
