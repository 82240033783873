import React, {useCallback, useEffect, useReducer} from 'react';
import Loader from 'react-loader';
import {useTranslations} from '@vidiemme/react-i18n';
import {useParams} from 'react-router-dom';

import './index.scss';
import Header from '../../molecules/Header';
import Drawer from '../../molecules/Drawer';
import NotificationBar from '../../atoms/NotificationBar';
import Btn from '../../atoms/Btn';
import Checkbox from '../../atoms/Checkbox';
import Radiobutton from '../../atoms/Radiobutton';

import DocumentsStore from '../../../stores/DocumentsStore';
import UserStore from '../../../stores/UserStore';
import DocumentsActions from '../../../actions/DocumentsActions';

import APIutils from '../../../utils/APIutils';

function reducer(state, action) {
  switch (action.type) {
    case 'updateState':
      return {
        ...state,
        ...action.newState,
      };
    default:
      throw new Error();
  }
}

const DocumentDetailsPage = props => {
  const {t} = useTranslations();
  const {documentId} = useParams();

  const initialState = {
    loaded: false,
    documentDetailsId: documentId,
    documentDetails: [],
    viewed: false,
    agreement: -1,
    conflict: -1,
    textConflict1: '',
    textConflict2: '',
    textConflict3: '',
    companycarbirthplace: '',
    companycarbirthdate: '',
    companycarcity: '',
    companycaraddress: '',
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const _onClickAcceptDocBtn = useCallback(e => {
    e.preventDefault();

    if (state.documentDetails.documentType === 'disclaimer') {
      DocumentsActions.setDisclaimerAccepted(
        UserStore.getUserData(),
        state.documentDetailsId,
        state.agreement === 1 ? 'true' : 'false',
      );
    } else if (state.documentDetails.documentType === 'conflictofinterest') {
      DocumentsActions.setConflictOfInterestAccepted(
        UserStore.getUserData(),
        state.documentDetailsId,
        state.conflict === 1 ? 'true' : 'false',
        state.textConflict1,
        state.textConflict2,
        state.textConflict3,
      );
    } else {
      DocumentsActions.setAccepted(
        UserStore.getUserData(),
        state.documentDetailsId,
      );
    }
    dispatch({type: 'updateState', newState: {loaded: false}});
  }, []);

  const _clickViewedCheck = useCallback(() => {
    dispatch({type: 'updateState', newState: {viewed: !state.viewed}});
  }, []);

  const _clickAgreementCheck = useCallback(val => {
    dispatch({type: 'updateState', newState: {agreement: val}});
  }, []);

  const _clickConflictCheck = useCallback(val => {
    dispatch({type: 'updateState', newState: {conflict: val}});

    if (val === 0) {
      dispatch({
        type: 'updateState',
        newState: {
          textConflict1: '',
          textConflict2: '',
          textConflict3: '',
        },
      });
    }
  }, []);

  const _createUserAuthTkn = useCallback(() => {
    let user = UserStore.getUserData();
    return user.accessToken;
  }, []);

  const handleChangeConflictFields = useCallback(el => {
    dispatch({
      type: 'updateState',
      newState: {[el.target.name]: el.target.value},
    });
  }, []);

  const _getCompanyCarUsePDFData = useCallback(() => {
    return (
      'birthPlace=' +
      state.companycarbirthplace +
      '&birthDate=' +
      state.companycarbirthdate +
      '&city=' +
      state.companycarcity +
      '&address=' +
      state.companycaraddress
    );
  }, [
    state.companycaraddress,
    state.companycarbirthdate,
    state.companycarbirthplace,
    state.companycarcity,
  ]);

  const getDocumentDetailsData = useCallback(documentId => {
    return DocumentsStore.getDocumentDetails(documentId);
  }, []);

  const _onDocumentDetailsChange = useCallback(() => {
    const documentDetails = getDocumentDetailsData(documentId);
    if (documentDetails.documentType) {
      documentDetails.documentType = documentDetails.documentType
        .replace(/ /g, '')
        .toLowerCase();
    }

    dispatch({
      type: 'updateState',
      newState: {
        documentDetails,
        documentDetailsId: state.documentDetailsId,
        loaded: true,
        conflict: -1,
        textConflict1: documentDetails.text1 || '',
        textConflict2: documentDetails.text2 || '',
        textConflict3: documentDetails.text3 || '',
      },
    });
  }, []);

  useEffect(() => {
    DocumentsStore.addDetailsChangeListener(_onDocumentDetailsChange);
    DocumentsActions.getDocumentDetails(UserStore.getUserData(), documentId);

    return () => {
      DocumentsStore.removeDetailsChangeListener(_onDocumentDetailsChange);
    };
  }, []);

  let additionalTxtDiv = '',
    additionalTxtDivCoi = '',
    checkboxAdditionalTxtDiv = '',
    agreementTxtDiv = '',
    checkboxAgreementDiv = '',
    buttonsDiv = '';

  if (state.documentDetails.text && state.documentDetails.text !== '') {
    agreementTxtDiv = (
      <div className="col-xs-12">
        <textarea disabled rows="5" defaultValue={state.documentDetails.text} />
      </div>
    );

    if (state.documentDetails.acceptedAt == null) {
      if (state.documentDetails.documentType === 'disclaimer') {
        checkboxAgreementDiv = (
          <div className="col-xs-12">
            <Radiobutton
              label={t('documentDetails.agreement')}
              name="agreementRadio"
              val="1"
              checked={state.agreement === 1}
              onClick={() => _clickAgreementCheck(1)}
            />
            <Radiobutton
              label={t('documentDetails.notAgreement')}
              name="agreementRadio"
              val="0"
              checked={state.agreement === 0}
              onClick={() => _clickAgreementCheck(0)}
            />
          </div>
        );
      } else if (state.documentDetails.documentType === 'conflictofinterest') {
        checkboxAgreementDiv = (
          <div className="col-xs-12">
            <Radiobutton
              label={t('documentDetails.noConflict')}
              name="conflictRadio"
              val="0"
              checked={state.conflict === 0}
              onClick={() => _clickConflictCheck(0)}
            />
            <Radiobutton
              label={t('documentDetails.conflict')}
              name="conflictRadio"
              val="1"
              checked={state.conflict === 1}
              onClick={() => _clickConflictCheck(1)}
            />
          </div>
        );
      }
      checkboxAdditionalTxtDiv = (
        <div className="col-xs-12">
          <Checkbox
            label={t(
              'documentDetails.viewed.' + state.documentDetails.documentType,
            )}
            checked={state.viewed}
            onClick={_clickViewedCheck}
          />
        </div>
      );

      if (
        state.documentDetails.documentType === 'document' ||
        state.agreement !== -1 ||
        state.documentDetails.documentType === 'mixedcaruseauth' ||
        (state.documentDetails.documentType === 'conflictofinterest' &&
          (state.conflict === 0 ||
            (state.conflict === 1 &&
              (state.textConflict1.length > 0 ||
                state.textConflict2.length > 0 ||
                state.textConflict3.length > 0))))
      ) {
        buttonsDiv = (
          <div className="row buttons">
            <div className="col-xs-offset-8 col-xs-4 col-sm-offset-10 col-sm-2">
              <Btn
                id="acceptbtn"
                label="accept"
                onClick={_onClickAcceptDocBtn}
              />
            </div>
          </div>
        );
      }
    }
  }
  if (
    (state.documentDetails.documentType === 'disclaimer' ||
      state.documentDetails.documentType === 'conflictofinterest' ||
      state.documentDetails.documentType === 'companycaruseauth') &&
    state.documentDetails.additionalText &&
    state.documentDetails.additionalText !== ''
  ) {
    additionalTxtDiv = (
      <div className="col-xs-12">
        <textarea
          disabled
          rows="5"
          defaultValue={state.documentDetails.additionalText}
        />
      </div>
    );
  }
  if (
    state.documentDetails.documentType === 'conflictofinterest' &&
    state.documentDetails.additionalText1
  ) {
    additionalTxtDivCoi = (
      <textarea
        disabled
        rows="5"
        defaultValue={state.documentDetails.additionalText1}
      />
    );
  }

  let statusField = '';
  let agreementOrConflictField = '';

  if (state.documentDetails.acceptedAt) {
    statusField = (
      <div className="field col-xs-6 col-sm-6">
        <span className="entry">{t('documentDetails.status')}: </span>
        <span>{t('documents.accepted')}</span>
      </div>
    );
    if (state.documentDetails.documentType === 'disclaimer') {
      agreementOrConflictField = (
        <div className="field col-xs-6 col-sm-6">
          <span className="entry">{t('documentDetails.agreed')}: </span>
          <span>
            {t(
              'documentDetails.' +
                (state.documentDetails.agreed ? 'yes' : 'no'),
            )}
          </span>
        </div>
      );
    } else if (state.documentDetails.documentType === 'conflictofinterest') {
      agreementOrConflictField = (
        <div className="field col-xs-6 col-sm-6">
          <span className="entry">{t('documentDetails.conflicted')}: </span>
          <span>
            {t(
              'documentDetails.' +
                (state.documentDetails.isConflict ? 'yes' : 'no'),
            )}
          </span>
        </div>
      );
    }
  } else {
    statusField = (
      <div className="field col-xs-12 col-sm-6">
        <span className="entry">{t('documentDetails.status')}: </span>
        <span>{t('documents.toAccept')}</span>
      </div>
    );
  }

  return (
    <div className="page document-details">
      <Header menu={true} active="DOCUMENTS" />
      <Drawer active="DOCUMENTS" />
      <NotificationBar />
      <Loader loaded={state.loaded}>
        <div className="main-container zebra">
          <div className="container-fluid container-custom">
            <div className="row section-header">
              <div className="col-xs-12">{t('documentDetails.title')}</div>
            </div>
            <div className="row document-event-container">
              {state.documentDetails.documentType === 'companycaruseauth' ? (
                <div className="col-xs-12 alert">
                  <span className="entry ">
                    {t('documentDetails.companycarprintmessage')}{' '}
                  </span>
                </div>
              ) : null}

              <div className="col-xs-12">
                <div className="row fields-list policy-div">
                  <div className="field col-xs-12 col-sm-6">
                    <span className="entry">
                      {t('documentDetails.assigned')}:{' '}
                    </span>
                    <span>{state.documentDetails.documentName}</span>
                  </div>
                  <div className="field col-xs-6 col-sm-6">
                    <span className="entry">{t('documentDetails.type')}: </span>
                    <span>
                      {t(
                        'documentDetails.typevalue.' +
                          state.documentDetails.documentType,
                      )}
                    </span>
                  </div>
                  <div className="field col-xs-6 col-sm-6">
                    <span className="entry">{t('documents.date')}: </span>
                    <span>{state.documentDetails.createdAt}</span>
                  </div>
                  {statusField}
                  {agreementOrConflictField}
                </div>
                <div className="row fields-list policy-div">
                  {agreementTxtDiv}
                  {checkboxAgreementDiv}
                  {state.documentDetails.documentType ===
                  'companycaruseauth' ? (
                    <div className="col-xs-12">
                      <div className="row">
                        <div className="col-xs-12 col-sm-2">
                          <span className="entry inlinelabel">
                            {t('documentDetails.companycarbirthplace')}
                            *:{' '}
                          </span>
                        </div>
                        <div className="col-xs-12 col-sm-10">
                          <input
                            type="text"
                            name="companycarbirthplace"
                            id="companycarbirthplacetxt"
                            placeholder={t(
                              'documentDetails.conflictFieldPlaceholder',
                            )}
                            onChange={handleChangeConflictFields}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-12 col-sm-2">
                          <span className="entry inlinelabel">
                            {t('documentDetails.companycarbirthdate')}
                            *:{' '}
                          </span>
                        </div>
                        <div className="col-xs-12 col-sm-10">
                          <input
                            type="text"
                            name="companycarbirthdate"
                            id="companycarbirthdatetxt"
                            placeholder={t(
                              'documentDetails.conflictFieldPlaceholder',
                            )}
                            onChange={handleChangeConflictFields}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-12 col-sm-2">
                          <span className="entry inlinelabel">
                            {t('documentDetails.companycarcity')}*:{' '}
                          </span>
                        </div>
                        <div className="col-xs-12 col-sm-10">
                          <input
                            type="text"
                            name="companycarcity"
                            id="companycarcitytxt"
                            placeholder={t(
                              'documentDetails.conflictFieldPlaceholder',
                            )}
                            onChange={handleChangeConflictFields}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-12 col-sm-2">
                          <span className="entry inlinelabel">
                            {t('documentDetails.companycaraddress')}*:{' '}
                          </span>
                        </div>
                        <div className="col-xs-12 col-sm-10">
                          <input
                            type="text"
                            name="companycaraddress"
                            id="companycaraddresstxt"
                            placeholder={t(
                              'documentDetails.conflictFieldPlaceholder',
                            )}
                            onChange={handleChangeConflictFields}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {additionalTxtDiv}
                  {state.documentDetails.documentType ===
                  'conflictofinterest' ? (
                    <div className="col-xs-12">
                      <input
                        type="text"
                        name="textConflict1"
                        id="textConflict1"
                        placeholder={t(
                          'documentDetails.conflictFieldPlaceholder',
                        )}
                        disabled={
                          state.conflict !== 1 &&
                          state.documentDetails.acceptedAt == null
                        }
                        onChange={handleChangeConflictFields}
                        value={state.textConflict1}
                      />
                      <input
                        type="text"
                        name="textConflict2"
                        id="textConflict2"
                        placeholder={t(
                          'documentDetails.conflictFieldPlaceholder',
                        )}
                        disabled={
                          state.conflict !== 1 &&
                          state.documentDetails.acceptedAt == null
                        }
                        onChange={handleChangeConflictFields}
                        value={state.textConflict2}
                      />
                      <input
                        type="text"
                        name="textConflict3"
                        id="textConflict3"
                        placeholder={t(
                          'documentDetails.conflictFieldPlaceholder',
                        )}
                        disabled={
                          state.conflict !== 1 &&
                          state.documentDetails.acceptedAt == null
                        }
                        onChange={handleChangeConflictFields}
                        value={state.textConflict3}
                      />
                      {additionalTxtDivCoi}
                    </div>
                  ) : null}
                  {state.documentDetails.hash ? (
                    <div className="col-xs-12 ">
                      <a
                        className="download-link"
                        href={
                          APIutils.getBaseUrl() +
                          '/asset/' +
                          state.documentDetails.hash
                        }
                        target="_blank"
                        rel="noreferrer">
                        {t(
                          'documentDetails.download.' +
                            state.documentDetails.documentType,
                        )}
                      </a>
                    </div>
                  ) : null}

                  {state.documentDetails.documentType === 'mixedcaruseauth' ? (
                    <div className="col-xs-12 ">
                      <a
                        className="download-link"
                        href={
                          APIutils.getBaseUrl() +
                          '/services/export/mixed_car_use_auth/' +
                          documentId +
                          '?Authorization=' +
                          _createUserAuthTkn()
                        }
                        target="_blank"
                        rel="noreferrer">
                        {t(
                          'documentDetails.download.' +
                            state.documentDetails.documentType,
                        )}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-xs-12">
                {buttonsDiv}
                {state.documentDetails.documentType === 'companycaruseauth' &&
                state.companycarbirthplace !== '' &&
                state.companycarbirthdate !== '' &&
                state.companycarcity !== '' &&
                state.companycaraddress !== '' ? (
                  <div className="col-xs-12 ">
                    <a
                      className="download-link"
                      href={
                        APIutils.getBaseUrl() +
                        '/services/export/car_use_auth/' +
                        documentId +
                        '?Authorization=' +
                        _createUserAuthTkn() +
                        '&' +
                        _getCompanyCarUsePDFData()
                      }
                      target="_blank"
                      rel="noreferrer">
                      <div className="row buttons">
                        <div className="col-xs-offset-8 col-xs-4 col-sm-offset-10 col-sm-2">
                          <Btn id="acceptbtn" label="print" />
                        </div>
                      </div>
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </div>
  );
};

export default DocumentDetailsPage;
