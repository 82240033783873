import React, {useCallback, useMemo} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import classnames from 'classnames';

import './index.scss';
import {useHistory} from 'react-router';

const DocumentListItem = ({
  data: {acceptedAt, documentName, documentType, createdAt, documentId},
}) => {
  const {t} = useTranslations();
  const {push} = useHistory();

  const _goToDetails = useCallback(() => {
    push('/document-detail/' + documentId);
  }, []);

  const [highlighted, button, status] = useMemo(() => {
    if (acceptedAt) {
      return ['arrow', '', 'documents.accepted'];
    }
    return ['highlighted', 'GO', 'documents.toAccept'];
  }, [acceptedAt]);

  return (
    <tr className={classnames('document-item', highlighted)}>
      <td className="bullet-column" />
      <td className="description">{documentName}</td>
      <td>{documentType}</td>
      <td>{createdAt}</td>
      <td>{t(status)}</td>
      <td>
        <div
          className={classnames('button', highlighted)}
          onClick={_goToDetails}>
          {button}
        </div>
      </td>
    </tr>
  );
};

export default React.memo(DocumentListItem);
