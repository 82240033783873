import {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router';
import Popup from 'react-popup';
import {useTranslations} from '@vidiemme/react-i18n';
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import {useIsAuthenticated} from '@azure/msal-react';

import './index.scss';

import Btn from '../../atoms/Btn';
import PopupBody from '../../templates/PopupBody';

import UserActions from '../../../actions/UserActions';
import UserStore from '../../../stores/UserStore';
import {loginRequest} from '../../../utils/authConfig';

// Handle SSO login
async function handleLogin(instance) {
  await instance.loginRedirect(loginRequest).catch(async e => {
    console.error(e);
    try {
      // Try forcing a session storage and cookies clear to reset any unresolved msal instance
      sessionStorage.clear();
      document.cookie.split(';').forEach(c => {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });
      // Then retry to login
      await instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
      });
    } catch (err) {
      console.error(err);
    }
  });
}

async function handleLogout(instance) {
  await instance
    .logoutRedirect()
    .catch(e => {
      console.error(e);
    })
    .then(UserActions.logout);
}

const LoginForm = props => {
  const {instance, accounts} = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const {t} = useTranslations();
  const {replace} = useHistory();

  const onLogin = useCallback(() => {
    replace('/tools');
  }, []);

  const retrieveAccessToken = useCallback(async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    const userData = UserStore.getUserData();

    if (!userData) {
      await instance
        .acquireTokenSilent(request)
        .then(response => {
          UserActions.login(response.accessToken);
        })
        .catch(async error => {
          await instance
            .acquireTokenRedirect(request)
            .then(response => {
              UserActions.login(response.accessToken);
            })
            .catch(function (error) {
              // Acquire token interactive failure
              console.log(error);
            });
          console.log(error);
        });
    }
  }, [accounts, instance]);

  const onLoginError = useCallback(() => {
    Popup.create({
      title: null,
      className: 'alert center',
      buttons: {
        left: [],
        right: ['ok'],
      },
      content: (
        <PopupBody title={t('error.error')}>
          {t('login.error.failed')}
        </PopupBody>
      ),
    });
  }, []);

  const onExpirationMailSent = useCallback(() => {
    Popup.create({
      title: null,
      content: (
        <PopupBody title={t('gen.attention')}>
          {t('login.recovery.expired')}
        </PopupBody>
      ),
      className: 'alert center',
      buttons: {
        right: ['ok'],
      },
    });
  }, []);

  const _onSubmit = useCallback(async () => {
    await handleLogin(instance);
  }, [instance]);

  useEffect(() => {
    (async () => {
      await instance.handleRedirectPromise();
    })();

    UserStore.addChangeListener(onLogin);
    UserStore.addLoginErrorListener(onLoginError);
    UserStore.addExpirationMailSentListener(onExpirationMailSent);

    return () => {
      UserStore.removeChangeListener(onLogin);
      UserStore.removeLoginErrorListener(onLoginError);
      UserStore.removeExpirationMailSentListener(onExpirationMailSent);
    };
  }, []);

  // Login after authentication with Azure SSO
  useEffect(() => {
    if (isAuthenticated) {
      retrieveAccessToken();
    }
  }, [isAuthenticated, retrieveAccessToken]);

  return (
    <div className="login-form zebra">
      <div className="container-fluid container-custom">
        <div className="row">
          <div className="col-xs-12">
            <UnauthenticatedTemplate>
              <Btn label={t('gen.loginSso')} onClick={_onSubmit}></Btn>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Btn
                label={t('gen.retry')}
                onClick={() => retrieveAccessToken()}></Btn>
              <Btn
                label={'logout'}
                onClick={() => handleLogout(instance)}></Btn>
            </AuthenticatedTemplate>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
