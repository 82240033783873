import Events from 'events';
import assign from 'object-assign';

import AppDispatcher from '../dispatcher';
import DocumentsConstants from '../constants/DocumentsConstants';
import UserActions from '../actions/UserActions';
import APIutils from '../utils/APIutils';

let EventEmitter = Events.EventEmitter;
let CHANGE_EVENT = 'doc_change';
const CHANGEDETAILS_EVENT = 'doc_changedetails';
var _documents = {};
var _documentDetails = {};

function getDocuments(user) {
  return APIutils.getDocuments(user).then(function (data) {
    _documents = data.response;
  });
}

function getDocumentDetails(user, documentId) {
  return APIutils.getDocumentDetails(user, documentId).then(function (data) {
    _documentDetails = data.response;
  });
}

function setAccepted(user, documentId) {
  return APIutils.setDocumentAccepted(user, documentId);
}

function setDisclaimerAccepted(user, documentId, agreed) {
  return APIutils.setDisclaimerAccepted(user, documentId, agreed);
}

function setConflictOfInterestAccepted(
  user,
  documentId,
  conflict,
  text1,
  text2,
  text3,
) {
  return APIutils.setConflictOfInterestAccepted(
    user,
    documentId,
    conflict,
    text1,
    text2,
    text3,
  );
}

let DocumentsStore = assign({}, EventEmitter.prototype, {
  /**
   * @return {object}
   */
  getDocuments: function () {
    return _documents;
  },

  getDocumentDetails: function (documentId) {
    return _documentDetails;
  },

  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },

  emitDetailsChange: function () {
    this.emit(CHANGEDETAILS_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback);
  },

  /**
   * @param {function} callback
   */
  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  addDetailsChangeListener: function (callback) {
    this.on(CHANGEDETAILS_EVENT, callback);
  },

  removeDetailsChangeListener: function (callback) {
    this.removeListener(CHANGEDETAILS_EVENT, callback);
  },
});

AppDispatcher.register(function (action) {
  switch (action.actionType) {
    case DocumentsConstants.GET_DOCUMENTS:
      getDocuments(action.user).then(function () {
        DocumentsStore.emitChange();
      });
      break;
    case DocumentsConstants.GET_DOCUMENT_DETAILS:
      getDocumentDetails(action.user, action.documentId).then(function () {
        DocumentsStore.emitDetailsChange();
      });
      break;
    case DocumentsConstants.SET_DOCUMENT_ACCEPTED:
      setAccepted(action.user, action.documentId)
        .then(function () {
          return getDocumentDetails(action.user, action.documentId);
        })
        .then(function () {
          UserActions.decrementDocumentsToBeAccepted();
          DocumentsStore.emitDetailsChange();
        });
      break;
    case DocumentsConstants.SET_DISCLAIMER_ACCEPTED:
      setDisclaimerAccepted(action.user, action.documentId, action.agreed)
        .then(function () {
          return getDocumentDetails(action.user, action.documentId);
        })
        .then(function () {
          UserActions.decrementDocumentsToBeAccepted();
          DocumentsStore.emitDetailsChange();
        });
      break;
    case DocumentsConstants.SET_CONFLICT_OF_INTEREST_ACCEPTED:
      setConflictOfInterestAccepted(
        action.user,
        action.documentId,
        action.isConflict,
        action.text1,
        action.text2,
        action.text3,
      )
        .then(function () {
          return getDocumentDetails(action.user, action.documentId);
        })
        .then(function () {
          UserActions.decrementDocumentsToBeAccepted();
          DocumentsStore.emitDetailsChange();
        });
      break;
    default:
    // no op
  }
});

export default DocumentsStore;
