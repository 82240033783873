import AppDispatcher from '../dispatcher';
import DepartmentsConstants from '../constants/DepartmentsConstants';

var DepartmentsActions = {
  getDepartments: function (user) {
    AppDispatcher.dispatch({
      actionType: DepartmentsConstants.GET_DEPARTMENTS,
      user: user,
    });
  },
};

export default DepartmentsActions;
