import {useEffect} from 'react';

import './index.css';
import Header from '../../molecules/Header';
import Drawer from '../../molecules/Drawer';
import NotificationBar from '../../atoms/NotificationBar';
import LoginForm from '../../organisms/LoginForm';
import DrawerActions from '../../../actions/DrawerActions';

const LoginPage = () => {
  useEffect(() => {
    DrawerActions.close();
  }, []);
  return (
    <div className="page login">
      <Header menu={false} />
      <Drawer />
      <NotificationBar />
      <div className="main-container">
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
