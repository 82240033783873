import Events from 'events';
import assign from 'object-assign';

import AppDispatcher from '../dispatcher';
import ToolsConstants from '../constants/ToolsConstants';
import APIutils from '../utils/APIutils';

let EventEmitter = Events.EventEmitter;
let CHANGE_EVENT = 'change';
const CHANGEDETAILS_EVENT = 'changedetails';
var _tools = [];
var _toolDetails = {};

function getTools(user) {
  return APIutils.getTools(user).then(function (data) {
    _tools = data.response;
  });
}

function getToolDetails(user, toolId) {
  return APIutils.getToolDetails(user, toolId).then(function (data) {
    _toolDetails = data.response;
  });
}

function setTheft(user, toolDetailsId, doc) {
  return APIutils.setTheft(user, toolDetailsId, doc);
}

function setDamaged(user, toolDetailsId, doc) {
  return APIutils.setDamaged(user, toolDetailsId, doc);
}

function setLost(user, toolDetailsId, doc) {
  return APIutils.setLost(user, toolDetailsId, doc);
}

function setAccept(user, toolDetailsId) {
  return APIutils.setAccept(user, toolDetailsId);
}

function setPolicyAccept(user, toolDetailsId) {
  return APIutils.setPolicyAccept(user, toolDetailsId);
}

let ToolsStore = assign({}, EventEmitter.prototype, {
  /**
   * @return {object}
   */
  getTools: function () {
    return _tools;
  },

  getToolDetails: function (toolId) {
    return _toolDetails;
  },

  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },

  emitDetailsChange: function () {
    this.emit(CHANGEDETAILS_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback);
  },

  /**
   * @param {function} callback
   */
  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  addDetailsChangeListener: function (callback) {
    this.on(CHANGEDETAILS_EVENT, callback);
  },

  removeDetailsChangeListener: function (callback) {
    this.removeListener(CHANGEDETAILS_EVENT, callback);
  },
});

AppDispatcher.register(function (action) {
  switch (action.actionType) {
    case ToolsConstants.GET_TOOLS:
      getTools(action.user).then(function () {
        ToolsStore.emitChange();
      });
      break;
    case ToolsConstants.GET_TOOL_DETAILS:
      getToolDetails(action.user, action.toolId).then(function () {
        ToolsStore.emitDetailsChange();
      });
      break;
    case ToolsConstants.SET_THEFT:
      setTheft(action.user, action.toolDetailsId, action.doc)
        .then(function () {
          return getToolDetails(action.user, action.toolDetailsId);
        })
        .then(function () {
          ToolsStore.emitDetailsChange();
        });
      break;
    case ToolsConstants.SET_DAMAGED:
      setDamaged(action.user, action.toolDetailsId, action.doc)
        .then(function () {
          return getToolDetails(action.user, action.toolDetailsId);
        })
        .then(function () {
          ToolsStore.emitDetailsChange();
        });
      break;
    case ToolsConstants.SET_LOST:
      setLost(action.user, action.toolDetailsId, action.doc)
        .then(function () {
          return getToolDetails(action.user, action.toolDetailsId);
        })
        .then(function () {
          ToolsStore.emitDetailsChange();
        });
      break;
    case ToolsConstants.SET_ACCEPTED:
      setAccept(action.user, action.toolDetailsId)
        .then(function () {
          return getToolDetails(action.user, action.toolDetailsId);
        })
        .then(function () {
          ToolsStore.emitDetailsChange();
        });
      break;
    case ToolsConstants.SET_POLICYACCEPTED:
      setPolicyAccept(action.user, action.toolDetailsId)
        .then(function () {
          return getToolDetails(action.user, action.toolDetailsId);
        })
        .then(function () {
          ToolsStore.emitDetailsChange();
        });
      break;
    default:
    // no op
  }
});

export default ToolsStore;
