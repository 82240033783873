import AppDispatcher from '../dispatcher';
import ToolsConstants from '../constants/ToolsConstants';

var ToolsActions = {
  getTools: function (user) {
    AppDispatcher.dispatch({
      actionType: ToolsConstants.GET_TOOLS,
      user: user,
    });
  },
  getToolDetails: function (user, toolId) {
    AppDispatcher.dispatch({
      actionType: ToolsConstants.GET_TOOL_DETAILS,
      user: user,
      toolId: toolId,
    });
  },
  setTheft: function (user, toolDetailsId, doc) {
    AppDispatcher.dispatch({
      actionType: ToolsConstants.SET_THEFT,
      user: user,
      doc: doc,
      toolDetailsId: toolDetailsId,
    });
  },
  setLost: function (user, toolDetailsId, doc) {
    AppDispatcher.dispatch({
      actionType: ToolsConstants.SET_LOST,
      user: user,
      doc: doc,
      toolDetailsId: toolDetailsId,
    });
  },
  setDamaged: function (user, toolDetailsId, doc) {
    AppDispatcher.dispatch({
      actionType: ToolsConstants.SET_DAMAGED,
      user: user,
      doc: doc,
      toolDetailsId: toolDetailsId,
    });
  },
  setAccepted: function (user, toolDetailsId) {
    AppDispatcher.dispatch({
      actionType: ToolsConstants.SET_ACCEPTED,
      user: user,
      toolDetailsId: toolDetailsId,
    });
  },
  setPolicyAccepted: function (user, toolDetailsId) {
    AppDispatcher.dispatch({
      actionType: ToolsConstants.SET_POLICYACCEPTED,
      user: user,
      toolDetailsId: toolDetailsId,
    });
  },
};

export default ToolsActions;
