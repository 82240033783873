import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslations} from '@vidiemme/react-i18n';

import '../../pages/ToolsPage/index.scss';

const ToolsListItem = ({data}) => {
  const {t} = useTranslations();
  const {push} = useHistory();

  const _goToDetails = useCallback(() => {
    push('/tool-detail/' + data.toolUserId);
  }, [data.toolUserId, push]);

  const [highlighted, setHighlighted] = useState();
  const [button, setButton] = useState();

  useEffect(() => {
    if (data.notification) {
      setHighlighted('highlighted');
      setButton('GO');
    } else {
      setHighlighted('arrow');
      setButton('');
    }
  }, [data.notification]);

  return (
    <tr className={'tool-item ' + highlighted}>
      <td className="bullet-column">
        <span
          className="department-bullet"
          style={{backgroundColor: data.departmentColor}}></span>
      </td>
      <td className="description">{data.toolName}</td>
      <td className="web-only">{data.department}</td>
      <td>{data.dateAssignment}</td>
      <td>
        {t(data.event)}
        {data.event &&
          (data.event.toUpperCase() === 'TOOLS.EVENT.RETURNED' ||
            data.event.toUpperCase() === 'TOOLS.EVENT.TORETURN') &&
          data.dueDate && (
            <div>
              <span className="due-date">{data.dueDate}</span>
            </div>
          )}
      </td>
      <td>
        <div className={'button ' + highlighted} onClick={_goToDetails}>
          {button}
        </div>
      </td>
    </tr>
  );
};

export default React.memo(ToolsListItem);
