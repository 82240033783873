import React, {useCallback, useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import Popup from 'react-popup';
import {useTranslations} from '@vidiemme/react-i18n';

import './index.scss';

import DepartmentsActions from '../../../actions/DepartmentsActions';
import DepartmentsStore from '../../../stores/DepartmentsStore';
import UserStore from '../../../stores/UserStore';
import PopupBody from '../../templates/PopupBody';
import APIutils from '../../../utils/APIutils';

const ToolsFooter = () => {
  const {t} = useTranslations();
  const [departments, setDepartments] = useState([]);

  const _onDepartmentsChange = useCallback(() => {
    setDepartments(DepartmentsStore.getDepartments());
  }, []);

  useEffect(() => {
    DepartmentsActions.getDepartments(UserStore.getUserData());
    DepartmentsStore.addChangeListener(_onDepartmentsChange);
    return () => {
      DepartmentsStore.removeChangeListener(_onDepartmentsChange);
    };
  }, []);

  const _openPopup = useCallback(department => {
    let imgDepartment = '';

    if (department.img !== null) {
      imgDepartment = (
        <div className="img-container">
          <img
            alt=""
            src={APIutils.getBaseUrl() + '/asset/' + department.img}
          />
        </div>
      );
    }

    Popup.create({
      title: null,
      content: (
        <PopupBody
          title={t('popupToolFooter.title')}
          classProp="popup-map-department">
          <div className="department-name">
            {department.name ? department.name : ''}
          </div>
          {imgDepartment}
          <div className="department-descr">
            {department.mapDescription ? department.mapDescription : ''}
          </div>
        </PopupBody>
      ),
      className: 'center',
      buttons: {
        right: [
          {
            text: 'OK',
            className: '',
            action: function (popup) {
              //    let form = document.querySelector("form");
              //   	let fdata = new FormData(form);
              // ToolsActions.setDamaged(UserStore.getUserData(), _this.state.toolDetailsId, fdata);
              popup.close();
              // let state = _this.state;
              // state.loaded = false;
              // _this.setState(state);
            },
          },
        ],
      },
    });
  }, []);

  let departmentsList = [];
  let departmentPin;

  if (departments.length > 0) {
    departmentsList = departments.map((department, index) => {
      departmentPin = '';

      if (department.color) {
        departmentPin = department.color.toLowerCase();
      }
      return (
        <div
          className="department-item"
          key={'department' + index}
          onClick={() => _openPopup(department)}>
          <span className="mobile-only">{department.shortName}</span>{' '}
          <span className="web-only">{department.name}</span>
          <div className={'department-bullet ' + departmentPin}></div>
        </div>
      );
    });
  } else {
    departmentsList = <div>LOADING......</div>;
  }

  return (
    <div className="tools-footer">
      <div className="department-item web-only">{t('tools.department')}</div>
      {departmentsList}
    </div>
  );
};

export default withRouter(ToolsFooter);
