import {withRouter} from 'react-router';
import classnames from 'classnames';

import './index.scss';

const Checkbox = ({checked, label, onClick}) => {
  return (
    <div className="checkbox-custom-container">
      <div className="control-group">
        <label
          className={classnames('control', {
            'control--checkbox': checked,
          })}>
          {label}
          <input type="checkbox" onChange={onClick} />
          <div className="control__indicator" />
        </label>
      </div>
    </div>
  );
};

export default withRouter(Checkbox);
