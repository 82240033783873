import Events from 'events';
import assign from 'object-assign';

import AppDispatcher from '../dispatcher';
import UserConstants from '../constants/UserConstants';
import API from '../utils/APIutils';
import ErrorActions from '../actions/ErrorActions';

var EventEmitter = Events.EventEmitter;
const CHANGE_EVENT = 'change',
  VALIDATION_EVENT = 'validation',
  // CHECK_TOKEN_EVENT = 'check_token',
  RECOVERY_SENT_EVENT = 'recovery_sent',
  LOGIN_ERROR_EVENT = 'login_error',
  EXPIRATION_SENT_EVENT = 'expired',
  LOGOUT_EVENT = 'logout';

var UserStore = assign({}, EventEmitter.prototype, {
  getUserData() {
    let _user = localStorage.getItem('userdata');
    if (_user) {
      _user = JSON.parse(_user);
    }
    return _user;
  },
  setUserData(userdata) {
    localStorage.setItem('userdata', JSON.stringify(userdata));
  },
  unsetUserData() {
    localStorage.removeItem('userdata');
  },
  updateDocumentsToBeAccepted(docs) {
    let _user = localStorage.getItem('userdata');
    if (_user) {
      _user = JSON.parse(_user);
      _user.documentsToBeAccepted = docs;
      localStorage.setItem('userdata', JSON.stringify(_user));
    }
  },
  decrementDocumentsToBeAccepted() {
    let _user = localStorage.getItem('userdata');
    if (_user) {
      _user = JSON.parse(_user);
      if (_user.documentsToBeAccepted && _user.documentsToBeAccepted > 0) {
        _user.documentsToBeAccepted--;
      }
      localStorage.setItem('userdata', JSON.stringify(_user));
    }
  },

  // Modifica al modello
  _emitChange(data) {
    this.emit(CHANGE_EVENT, data);
  },
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  // Logout
  _emitLogout() {
    this.emit(LOGOUT_EVENT);
  },
  addLogoutListener(callback) {
    this.on(LOGOUT_EVENT, callback);
  },
  removeLogoutListener(callback) {
    this.removeListener(LOGOUT_EVENT, callback);
  },

  // Login error
  _emitLoginError() {
    this.emit(LOGIN_ERROR_EVENT);
  },
  addLoginErrorListener(callback) {
    this.on(LOGIN_ERROR_EVENT, callback);
  },
  removeLoginErrorListener(callback) {
    this.removeListener(LOGIN_ERROR_EVENT, callback);
  },

  // Validazione Login
  _emitValidationError(errors) {
    this.emit(VALIDATION_EVENT, errors);
  },
  addValidationErrorListener(callback) {
    this.on(VALIDATION_EVENT, callback);
  },
  removeValidationErrorListener(callback) {
    this.removeListener(VALIDATION_EVENT, callback);
  },

  //Recovery Mail sent
  _emitRecoveryMailSent() {
    this.emit(RECOVERY_SENT_EVENT);
  },
  addRecoveryMailSentListener(callback) {
    this.on(RECOVERY_SENT_EVENT, callback);
  },
  removeRecoveryMailSentListener(callback) {
    this.removeListener(RECOVERY_SENT_EVENT, callback);
  },

  //Expiration Mail sent
  _emitExpirationMailSent() {
    this.emit(EXPIRATION_SENT_EVENT);
  },
  addExpirationMailSentListener(callback) {
    this.on(EXPIRATION_SENT_EVENT, callback);
  },
  removeExpirationMailSentListener(callback) {
    this.removeListener(EXPIRATION_SENT_EVENT, callback);
  },
  // // Check token
  // _emitCheckToken(data) {
  // 	this.emit(CHECK_TOKEN_EVENT, data);
  // },
  // addCheckTokenListener(callback) {
  // 	this.on(CHECK_TOKEN_EVENT, callback);
  // },
  // removeCheckTokenListener(callback) {
  // 	this.removeListener(CHECK_TOKEN_EVENT, callback);
  // }
});

AppDispatcher.register(function (action) {
  // var username;

  switch (action.actionType) {
    case UserConstants.LOGIN:
      // if (action.data && action.data.username) {
      //   username = action.data.username.trim();
      // }
      if (action.token) {
        API.loginSSO(action.token).then(
          data => {
            UserStore.setUserData({
              ...data.response,
              accessToken: action.token,
            });
            UserStore._emitChange();
          },
          data => {
            if (data.status !== '401') ErrorActions.manageError(data);
            else UserStore._emitLoginError();
          },
        );
      }
      break;
    case UserConstants.LOGOUT:
      UserStore.unsetUserData();
      UserStore._emitLogout();
      break;
    case UserConstants.RECOVERY:
      API.recovery(action.user).then(
        () => {
          UserStore._emitRecoveryMailSent();
        },
        data => {
          ErrorActions.manageError(data);
        },
      );
      break;
    case UserConstants.UPDATE_DOCUMENTS:
      UserStore.updateDocumentsToBeAccepted(action.docs);
      UserStore._emitChange();
      break;
    case UserConstants.DECREMENT_DOCUMENTS_COUNT:
      UserStore.decrementDocumentsToBeAccepted();
      UserStore._emitChange();
      break;
    default:
    // no op
  }
});

export default UserStore;
