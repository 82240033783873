import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';

import LoginPage from '../components/pages/LoginPage';
import App from '../components/templates/App';
import ToolsPage from '../components/pages/ToolsPage';
import ToolDetailsPage from '../components/pages/ToolDetailsPage';
import DocumentsPage from '../components/pages/DocumentsPage';
import ProfilePage from '../components/pages/ProfilePage';
import NoMatch from '../components/pages/NoMatch';
import DocumentDetailsPage from '../components/pages/DocumentDetailsPage';
import Guest from './middleware/Guest';
import Authenticated from './middleware/Authenticated';

export const Navigation = () => {
  return (
    <Router>
      <App>
        <Switch>
          <Guest exact path="/" component={LoginPage} />
          <Authenticated path="/tools" component={ToolsPage} />
          <Authenticated
            path="/tool-detail/:toolUserId"
            component={ToolDetailsPage}
          />
          <Authenticated path="/documents" component={DocumentsPage} />
          <Authenticated
            path="/document-detail/:documentId"
            component={DocumentDetailsPage}
          />
          <Authenticated path="/profile" component={ProfilePage} />
          <Authenticated path="*" component={NoMatch} />
        </Switch>
      </App>
    </Router>
  );
};
