import React, {useCallback, useEffect, useState} from 'react';
import Loader from 'react-loader';
import {useTranslations} from '@vidiemme/react-i18n';

import './index.scss';
import Header from '../../molecules/Header';
import Drawer from '../../molecules/Drawer';
import NotificationBar from '../../atoms/NotificationBar';
import DocumentListItem from './DocumentListItem';

import UserStore from '../../../stores/UserStore';
import UserActions from '../../../actions/UserActions';
import DocumentsStore from '../../../stores/DocumentsStore';
import DocumentsActions from '../../../actions/DocumentsActions';

const DocumentsPage = ({router}) => {
  const {t} = useTranslations();

  const [loaded, setLoaded] = useState(true);
  const [documents, setDocuments] = useState(null);

  const getDocumentsData = useCallback(() => {
    return DocumentsStore.getDocuments();
  }, []);

  const _onDocumentsChange = useCallback(() => {
    const documentsData = getDocumentsData();
    setDocuments(documentsData);
    setLoaded(true);

    if (documentsData && documentsData.notAccepted) {
      UserActions.updateDocumentsToBeAccepted(documentsData.notAccepted.length);
    }
  }, []);

  useEffect(() => {
    DocumentsStore.addChangeListener(_onDocumentsChange);

    return () => {
      DocumentsStore.removeChangeListener(_onDocumentsChange);
    };
  }, []);

  useEffect(() => {
    DocumentsActions.getDocuments(UserStore.getUserData());
  }, []);

  let docsList = [];
  let docsListAccepted = [];

  if (documents == null) {
    docsList = (
      <tr>
        <td colSpan="5">LOADING......</td>
      </tr>
    );
  } else {
    if (
      documents &&
      documents.notAccepted &&
      documents.notAccepted.length > 0
    ) {
      documents.notAccepted.forEach((doc, index) => {
        docsList.push(
          <DocumentListItem router={router} key={'doc' + index} data={doc} />,
        );
      });
    }

    if (documents && documents.accepted && documents.accepted.length > 0) {
      documents.accepted.forEach((doc, index) => {
        docsListAccepted.push(
          <DocumentListItem router={router} key={'doc' + index} data={doc} />,
        );
      });
    }

    if (docsList.length === 0 && docsListAccepted.length === 0) {
      docsList = (
        <tr>
          <td colSpan="5">DOCUMENTS NOT AVAILABLE</td>
        </tr>
      );
    }
  }

  return (
    <div className="page documents">
      <Header menu={true} active="DOCUMENTS" />
      <Drawer active="DOCUMENTS" />
      <NotificationBar />
      <Loader loaded={loaded}>
        <div className="main-container zebra">
          <div className="container-fluid container-custom">
            <div className="row section-header">
              <div className="col-xs-12">{t('documents.title')}</div>
            </div>
            <div className="row documents-list-container">
              <div className="col-xs-12">
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>{t('documents.assigned')}</th>
                      <th>{t('documents.type')}</th>
                      <th>{t('documents.date')}</th>
                      <th>{t('documents.status')}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {docsList}
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                    {docsListAccepted.length > 0 ? docsListAccepted : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </div>
  );
};

export default DocumentsPage;
