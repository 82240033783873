import {useCallback, useEffect, useMemo, useState} from 'react';
import Loader from 'react-loader';
import {useTranslations} from '@vidiemme/react-i18n';
import Popup from 'react-popup';

import {useParams} from 'react-router-dom';

import './index.scss';

import Btn from '../../atoms/Btn';
import Checkbox from '../../atoms/Checkbox';
import NotificationBar from '../../atoms/NotificationBar';
import Drawer from '../../molecules/Drawer';
import Header from '../../molecules/Header';
import UploadForm from '../../molecules/UploadForm';
import ToolsFooter from '../../organisms/ToolsFooter';
import PopupBody from '../../templates/PopupBody';

import ToolsActions from '../../../actions/ToolsActions';
import ToolsStore from '../../../stores/ToolsStore';
import UserStore from '../../../stores/UserStore';
import APIutils from '../../../utils/APIutils';

function getToolDetailsData(toolUserId) {
  return ToolsStore.getToolDetails(toolUserId);
}

const ToolDetailsPage = () => {
  const {t} = useTranslations();

  const {toolUserId} = useParams();

  const [toolDetails, setToolDetails] = useState([]);
  const [toolDetailsId, setToolDetailsId] = useState(toolUserId);
  const [loaded, setLoaded] = useState(false);
  const [viewed, setViewed] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);

  const _onchangeInputFile = useCallback(() => {
    if (
      document.querySelector(".uploadForm input[name='documentation']") &&
      document.querySelector(".uploadForm input[name='documentation']")
        .value !== ''
    ) {
      //qui caricato file
      //document.querySelector(".mm-popup__btn--tool-details-popup-ok").className = document.querySelector(".mm-popup__btn--tool-details-popup-ok").className + " enabled";
      setFileLoaded(true);
    } else {
      //let newClassName = document.querySelector(".mm-popup__btn--tool-details-popup-ok").className.replace("enabled","");
      //document.querySelector(".mm-popup__btn--tool-details-popup-ok").className = newClassName;
      setFileLoaded(false);
    }
  }, []);

  const _showPopupOnClickTheft = useCallback(() => {
    let pbody = (
      <PopupBody title={t('toolDetails.uploadFile')}>
        <UploadForm onchangecb={() => _onchangeInputFile()} />
      </PopupBody>
    );
    let classnamepop = 'special-btn tool-details-popup-ok enabled';
    if (toolDetails?.toolName == 'AUTO') {
      pbody = (
        <PopupBody title="Info">
          Inserire la denuncia nel portale leaseplan.it o aldautomotive.it e
          spedire una mail a marialuisa.casiraghi@adidas-group.com per informare
          dell’accaduto
        </PopupBody>
      );
      classnamepop = classnamepop + ' enabled';
    }

    Popup.create({
      title: null,
      //content: <UploadForm onchangecb={this._onchangeInputFile}/>,
      content: pbody,
      className: 'alert',
      buttons: {
        left: ['cancel'],
        right: [
          {
            text: 'OK',
            className: classnamepop,
            action: popup => {
              var form = document.querySelector('form');
              var fdata = new FormData(form);
              ToolsActions.setTheft(
                UserStore.getUserData(),
                toolDetailsId,
                fdata,
              );
              popup.close();
              setLoaded(false);
            },
          },
        ],
      },
    });
  }, [_onchangeInputFile, t, toolDetails?.toolName, toolDetailsId]);

  const _showPopupOnClickDamage = useCallback(() => {
    Popup.create({
      title: null,
      content: (
        <PopupBody title={t('toolDetails.uploadFile')}>
          <UploadForm onchangecb={() => _onchangeInputFile()} />
        </PopupBody>
      ),
      className: 'alert',
      buttons: {
        left: ['cancel'],
        right: [
          {
            text: 'OK',
            className: 'special-btn tool-details-popup-ok enabled',
            action: function (popup) {
              var form = document.querySelector('form');
              var fdata = new FormData(form);
              ToolsActions.setDamaged(
                UserStore.getUserData(),
                toolDetailsId,
                fdata,
              );
              popup.close();
              setLoaded(false);
            },
          },
        ],
      },
    });
  }, [_onchangeInputFile, t, toolDetailsId]);

  const _onToolDetailsChange = useCallback(() => {
    setToolDetails(getToolDetailsData(toolUserId));
    setToolDetailsId(toolDetailsId);
    setLoaded(true);

    if (toolDetails?.event?.toUpperCase() === 'TOOLS.EVENT.ASSIGNED') {
      var theftbtn = document.querySelector('#theftbtn');
      theftbtn.addEventListener('click', e => {
        e.preventDefault();
        _showPopupOnClickTheft();
      });

      var lostbtn = document.querySelector('#lostbtn');

      if (lostbtn != null) {
        lostbtn.addEventListener('click', e => {
          e.preventDefault();
          Popup.create({
            title: null,
            content: (
              <PopupBody title={t('toolDetails.uploadFile')}>
                <UploadForm onchangecb={() => _onchangeInputFile()} />
              </PopupBody>
            ),
            className: 'alert',
            buttons: {
              left: ['cancel'],
              right: [
                {
                  text: 'OK',
                  className: 'special-btn tool-details-popup-ok enabled',
                  action: function (popup) {
                    var form = document.querySelector('form');
                    var fdata = new FormData(form);
                    ToolsActions.setLost(
                      UserStore.getUserData(),
                      toolDetailsId,
                      fdata,
                    );
                    popup.close();
                    setLoaded(false);
                  },
                },
              ],
            },
          });
        });
      }

      var damagedbtn = document.querySelector('#damagedbtn');
      damagedbtn.addEventListener('click', e => {
        e.preventDefault();

        if (toolDetails.toolName === 'AUTO') {
          Popup.create({
            title: null,
            content: <PopupBody title="Info">Istruzioni per l'uso</PopupBody>,
            className: 'alert',
            buttons: {
              right: [
                {
                  text: 'OK',
                  className: 'special-btn tool-details-popup-ok enabled',
                  action: popup => {
                    popup.close();
                    _showPopupOnClickDamage();
                  },
                },
              ],
            },
          });
        } else {
          _showPopupOnClickDamage();
        }
      });
    }

    if (toolDetails?.event?.toUpperCase() === 'TOOLS.EVENT.POLICYTOACCEPT') {
      var policyacceptbtn = document.querySelector('#policyacceptbtn');
      policyacceptbtn.addEventListener('click', function (e) {
        e.preventDefault();
        ToolsActions.setPolicyAccepted(UserStore.getUserData(), toolDetailsId);
        setLoaded(false);
      });
    }
  }, [
    _onchangeInputFile,
    _showPopupOnClickDamage,
    _showPopupOnClickTheft,
    t,
    toolDetails?.event,
    toolDetails.toolName,
    toolDetailsId,
    toolUserId,
  ]);

  const _onClickAcceptBtn = useCallback(
    e => {
      e.preventDefault();
      ToolsActions.setAccepted(UserStore.getUserData(), toolDetailsId);
      setLoaded(false);
    },
    [toolDetailsId],
  );

  const _clickViewedCheck = useCallback(() => {
    setViewed(!viewed);
  }, [viewed]);

  useEffect(() => {
    ToolsActions.getToolDetails(UserStore.getUserData(), toolUserId);
    ToolsStore.addDetailsChangeListener(_onToolDetailsChange);
    return () => {
      ToolsStore.removeDetailsChangeListener(_onToolDetailsChange);
    };
  }, [_onToolDetailsChange, toolUserId]);

  let fieldList = [];
  let policyTxtDiv = '';

  if (toolDetails?.fieldValueList?.length > 0) {
    fieldList = toolDetails.fieldValueList.map((field, index) => {
      if (field.fieldType && field.fieldType.toUpperCase() === 'PHOTO') {
        return (
          <div className="field col-xs-6 col-sm-6" key={'field' + index}>
            <span className="entry">{field.fieldLabel}: </span>
            <img
              alt=""
              className="photo"
              src={APIutils.getBaseUrl() + '/asset/' + field.fieldValue}
            />
          </div>
        );
      } else {
        return (
          <div className="field col-xs-6 col-sm-6" key={'field' + index}>
            <span className="entry">{field.fieldLabel}: </span>
            <span>{field.fieldValue}</span>
          </div>
        );
      }
    });

    fieldList.unshift(
      <div className="field col-xs-12" key="fielddescription">
        <span className="entry">{t('toolDetails.toolDescription')}: </span>
        <span>{toolDetails.toolDescription}</span>
      </div>,
    );

    fieldList.push(
      <div className="field col-xs-12" key="fieldnote">
        <span className="entry">{t('toolDetails.notes')}: </span>
        <span>{toolDetails.notes}</span>
      </div>,
    );
  } else {
    fieldList = <div>LOADING......</div>;
  }

  if (toolDetails.policy && toolDetails.policy !== '') {
    policyTxtDiv = (
      <div className="col-xs-12">
        <textarea rows="5" defaultValue={toolDetails.policy}></textarea>
      </div>
    );
  }

  const buttonsToolDetail = useMemo(() => {
    if (toolDetails.toolName === 'AUTO') {
      return (
        <div className="row buttons">
          <div className="col-xs-6 col-sm-offset-8 col-sm-2">
            <Btn id="theftbtn" label="theft"></Btn>
          </div>
          <div className="col-xs-6 col-sm-2">
            <Btn id="damagedbtn" label="damage"></Btn>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row buttons">
          <div className="col-xs-4 col-sm-offset-6 col-sm-2">
            <Btn id="theftbtn" label="theft"></Btn>
          </div>
          <div className="col-xs-4 col-sm-2">
            <Btn id="damagedbtn" label="damage"></Btn>
          </div>
          <div className="col-xs-4 col-sm-2">
            <Btn id="lostbtn" label="lost"></Btn>
          </div>
        </div>
      );
    }
  }, [toolDetails.toolName]);

  const buttonsDiv = useMemo(() => {
    if (
      toolDetails?.event?.toUpperCase() === 'TOOLS.EVENT.TOACCEPT' &&
      viewed
    ) {
      return (
        <div className="row buttons">
          <div className="col-xs-offset-8 col-xs-4 col-sm-offset-10 col-sm-2">
            <Btn
              id="acceptbtn"
              label="accept"
              onClick={_onClickAcceptBtn}></Btn>
          </div>
        </div>
      );
    } else if (toolDetails?.event?.toUpperCase() === 'TOOLS.EVENT.ASSIGNED') {
      return buttonsToolDetail;
    } else if (
      toolDetails?.event?.toUpperCase() === 'TOOLS.EVENT.POLICYTOACCEPT'
    ) {
      return (
        <div className="row buttons">
          <div className="col-xs-offset-8 col-xs-4 col-sm-offset-10 col-sm-2">
            <Btn id="policyacceptbtn" label="accept"></Btn>
          </div>
        </div>
      );
    }
  }, [_onClickAcceptBtn, buttonsToolDetail, toolDetails.event, viewed]);

  let dueDateNode = '';

  if (
    toolDetails.event &&
    (toolDetails.event.toUpperCase() === 'TOOLS.EVENT.RETURNED' ||
      toolDetails.event.toUpperCase() === 'TOOLS.EVENT.TORETURN') &&
    toolDetails.dueDate
  ) {
    dueDateNode = (
      <div>
        <span className="due-date">{toolDetails.dueDate}</span>
      </div>
    );
  }

  return (
    <div className="page tool-details">
      <Header menu={true} active="TOOLS" />
      <Drawer active="TOOLS" />
      <NotificationBar />
      <Loader loaded={loaded}>
        <div className="main-container zebra">
          <div className="container-fluid container-custom">
            <div className="row section-header">
              <div className="col-xs-12">{t('toolDetails.title')}</div>
            </div>
            <div className="row tool-event-container">
              <div className="col-xs-12">
                <table>
                  <thead>
                    <tr>
                      <th className="mobile-only"></th>
                      <th>{t('toolDetails.assignedTool')}</th>
                      <th className="web-only">
                        {t('toolDetails.department')}
                      </th>
                      <th>{t('toolDetails.date')}</th>
                      <th>{t('toolDetails.status')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="mobile-only bullet-column">
                        <span
                          className="department-bullet"
                          style={{
                            backgroundColor: toolDetails.departmentColor,
                          }}></span>
                      </td>
                      <td>{toolDetails.toolName}</td>
                      <td className="web-only">{toolDetails.department}</td>
                      <td>{toolDetails.dateAssignment}</td>
                      <td>
                        {t(toolDetails.event)} {dueDateNode}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-xs-12">
                <div className="row fields-list">{fieldList}</div>

                <div className="row fields-list policy-div">
                  {policyTxtDiv}

                  <div className="col-xs-12 ">
                    <a
                      className="download-link"
                      href={
                        APIutils.getBaseUrl() +
                        '/asset/' +
                        toolDetails?.policyFile
                      }
                      target="_blank"
                      rel="noreferrer">
                      {t('toolDetails.policy')}
                    </a>
                  </div>
                  {toolDetails?.event?.toUpperCase() ===
                    'TOOLS.EVENT.TOACCEPT' && (
                    <div className="col-xs-12">
                      <Checkbox
                        label={t('toolDetails.viewed')}
                        checked={viewed}
                        onClick={() => _clickViewedCheck()}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xs-12">{buttonsDiv}</div>
            </div>
          </div>
        </div>
        <ToolsFooter />
      </Loader>
    </div>
  );
};

export default ToolDetailsPage;
