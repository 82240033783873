import React, {useEffect, useCallback, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import Loader from 'react-loader';

import './index.scss';

import ToolsActions from '../../../actions/ToolsActions';
import UserStore from '../../../stores/UserStore';
import ToolsStore from '../../../stores/ToolsStore';
import NotificationBar from '../../atoms/NotificationBar';
import Header from '../../molecules/Header';
import Drawer from '../../molecules/Drawer';
import ToolsListItem from '../../molecules/ToolsListItem';
import ToolsFooter from '../../organisms/ToolsFooter';

const ToolsPage = props => {
  const {t} = useTranslations();

  const [tools, setTools] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const _onToolsChange = useCallback(() => {
    setTools(ToolsStore.getTools());
    setLoaded(true);
  }, []);

  useEffect(() => {
    const userData = UserStore.getUserData();
    if (userData) {
      ToolsActions.getTools(UserStore.getUserData());
      ToolsStore.addChangeListener(_onToolsChange);
    }
    return () => {
      ToolsStore.removeChangeListener(_onToolsChange);
    };
  }, [_onToolsChange]);

  let toolsList = [];
  let toolsListArchive = [];
  let traceRecentTools = [];

  if (tools === null) {
    toolsList = (
      <tr>
        <td colSpan="5">LOADING......</td>
      </tr>
    );
  } else if (tools.length > 0) {
    tools.map((tools, index) => {
      if (traceRecentTools.indexOf(tools.toolName) > -1) {
        // tipo di tool gia aggiunto alla lista recenti, lo aggiungo alla lista archivio
        toolsListArchive.push(
          <ToolsListItem key={'tool' + index} data={tools} />,
        );
      } else {
        // tipo di tool ancora non aggiunto tra quelli recenti
        traceRecentTools.push(tools.toolName);
        toolsList.push(<ToolsListItem key={'tool' + index} data={tools} />);
      }
    });
  } else {
    toolsList = (
      <tr>
        <td colSpan="5">TOOLS NOT AVAILABLE</td>
      </tr>
    );
  }

  return (
    <div className="page tools">
      <Header menu={true} active="TOOLS" />
      <Drawer active="TOOLS" />
      <NotificationBar />
      <Loader loaded={loaded}>
        <div className="main-container zebra">
          <div className="container-fluid container-custom">
            <div className="row section-header">
              <div className="col-xs-12">{t('tools.title')}</div>
            </div>
            <div className="row tools-list-container">
              <div className="col-xs-12">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t('tools.assignedTool')}</th>
                      <th className="web-only">{t('tools.department')}</th>
                      <th>{t('tools.date')}</th>
                      <th>{t('tools.status')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {toolsList}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {toolsListArchive.length > 0 ? toolsListArchive : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ToolsFooter />
      </Loader>
    </div>
  );
};

export default ToolsPage;
