import AppDispatcher from '../dispatcher';
import ErrorConstants from '../constants/ErrorConstants';

var ErrorActions = {
  manageError: function (data) {
    AppDispatcher.dispatch({
      actionType: ErrorConstants.GENERIC_ERROR,
      data: data,
    });
  },
};

export default ErrorActions;
