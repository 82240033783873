import {useEffect, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

import './index.scss';

import NotificationBar from '../../atoms/NotificationBar';
import Header from '../../molecules/Header';
import Drawer from '../../molecules/Drawer';

import APIutils from '../../../utils/APIutils';
import UserStore from '../../../stores/UserStore';

const ProfilePage = () => {
  const {t} = useTranslations();

  const userdata = UserStore.getUserData();
  const [percentage, setPercentage] = useState('0');

  useEffect(() => {
    let percentage = null;
    if (userdata.bonus) {
      percentage = userdata.bonus;
      document.querySelector('#progress-bar-id').style.width = percentage + '%';
    }
    setPercentage(percentage);
  }, [userdata.bonus]);

  let photoProfile = (
    <div className="temp-photo-border">
      <div className="temp-photo" />
    </div>
  );

  if (userdata.userPic !== null) {
    photoProfile = (
      <img alt="" src={APIutils.getBaseUrl() + '/asset/' + userdata.userPic} />
    );
  }

  let progressBar = '';

  if (percentage != null) {
    let colorProgress = 'progress-bar-info';
    if (percentage > 59 && percentage < 90) {
      colorProgress = 'progress-bar-warning';
    } else if (percentage > 89) {
      colorProgress = 'progress-bar-alert';
    }

    progressBar = (
      <div>
        <span>{t('profile.bonus')}</span>
        <div className="progress">
          <div id="progress-bar-id" className={'progress-bar ' + colorProgress}>
            <span className="progress-bar-value">{percentage}&#37;</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page profile">
      <Header menu={true} active="PROFILE" />
      <Drawer active="PROFILE" />
      <NotificationBar />
      <div className="main-container">
        <div className="profile">
          <div className="container-fluid container-custom">
            <div className="row section-header">
              <div className="col-xs-12">{t('profile.title')}</div>
            </div>
            <div className="row row-eq-height zebra profile-body">
              <div className="col-xs-12 col-sm-4 profile-pic-container">
                {photoProfile}
              </div>
              <div className="col-xs-12 col-sm-8 profile-info-container">
                <div className="profile-name">{userdata.name}</div>
                <div className="profile-secondinfo">
                  <span className="profile-email">{userdata.username}</span>
                  <br />
                  <span className="profile-role">{userdata.internalRole}</span>
                </div>
                <div className="profile-lastinfo">
                  <span>{userdata.manager}</span>
                  <br />
                  <span>{userdata.department}</span>
                </div>
                <div className="profile-bonus">
                  {progressBar != null ? progressBar : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
