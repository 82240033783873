import Events from 'events';
import assign from 'object-assign';

import AppDispatcher from '../dispatcher';
import DrawerConstants from '../constants/DrawerConstants';

const EventEmitter = Events.EventEmitter,
  CHANGE_STATE_EVENT = 'change_state';

var drawerState = false;

const DrawerStore = assign({}, EventEmitter.prototype, {
  getDrawerState() {
    return drawerState;
  },

  _emitChangeState(state) {
    this.emit(CHANGE_STATE_EVENT, state);
  },
  addChangeStateListener(callback) {
    this.on(CHANGE_STATE_EVENT, callback);
  },
  removeChangeStateListener(callback) {
    this.removeListener(CHANGE_STATE_EVENT, callback);
  },
});

AppDispatcher.register(function (action) {
  switch (action.actionType) {
    case DrawerConstants.TOGGLE:
      drawerState = !drawerState;
      DrawerStore._emitChangeState(drawerState);
      break;
    case DrawerConstants.CLOSE:
      drawerState = false;
      DrawerStore._emitChangeState(drawerState);
      break;
    default:
    // no op
  }
});

export default DrawerStore;
