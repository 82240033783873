import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import UserStore from '../../stores/UserStore';

const Guest = props => {
  const userData = UserStore.getUserData();

  if (userData) {
    return <Redirect to="/tools" />;
  } else {
    return <Route {...props} />;
  }
};

export default React.memo(Guest);
