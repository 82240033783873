import React from 'react';
import {Link} from 'react-router-dom';
import './index.css';

const DrawerElement = ({badge, className, link, active, label}) => {
  return (
    <div className="row">
      <div className={'col-xs-12 drawer-element ' + className}>
        <Link to={link}>
          <div className={active ? 'active' : ''}>
            {label}
            {badge && badge > 0 && <span className="badge">({badge})</span>}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default React.memo(DrawerElement);
