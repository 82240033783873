import React, {useEffect, useState, useCallback} from 'react';
import {withRouter} from 'react-router';
import {useTranslations} from '@vidiemme/react-i18n';
import {useMsal} from '@azure/msal-react';

import './index.css';

import UserStore from '../../../stores/UserStore';
import DrawerActions from '../../../actions/DrawerActions';
import UserActions from '../../../actions/UserActions';
import MenuElement from './MenuElement';

function handleLogout(instance) {
  instance
    .logoutPopup()
    .catch(e => {
      console.error(e);
    })
    .then(UserActions.logout);
}

const Header = ({menu: p_menu, active}) => {
  const {instance} = useMsal();
  const [user, setUser] = useState(UserStore.getUserData());

  const _onUserChange = useCallback(() => {
    setUser(UserStore.getUserData());
  }, []);

  useEffect(() => {
    UserStore.addChangeListener(_onUserChange);

    return () => {
      UserStore.removeChangeListener(_onUserChange);
    };
  }, []);

  const {t} = useTranslations();

  let menu = '';
  if (p_menu) {
    let notification =
      user && user.documentsToBeAccepted && user.documentsToBeAccepted > 0;

    menu = (
      <div>
        <button
          type="button"
          className={'burger-button' + (notification ? ' highlighted' : '')}
          onClick={DrawerActions.toggle}>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>

        <ul className="menu-horizontal">
          <MenuElement
            active={active === 'TOOLS' ? true : false}
            url="/tools"
            label={t('menu.tools')}
          />
          <MenuElement
            active={active === 'DOCUMENTS' ? true : false}
            url="/documents"
            label={t('menu.documents')}
            badge={notification ? user.documentsToBeAccepted : '0'}
          />
          <MenuElement
            active={active === 'PROFILE' ? true : false}
            url="/profile"
            label={t('menu.profile')}
          />
        </ul>

        <ul className="contact-info-horizontal">
          <li>
            <span className="logged-user">{user ? user.name : ''}</span>
          </li>
          <li>
            <span
              className="logout-btn "
              onClick={() => handleLogout(instance)}>
              {t('menu.logout')}
            </span>
          </li>
        </ul>
      </div>
    );
  }

  return <div className="at-navbar">{menu}</div>;
};

export default withRouter(Header);
