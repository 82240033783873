import React from 'react';

import './index.scss';

const UploadForm = ({className, onchangecb}) => {
  return (
    <div className={'uploadForm ' + className}>
      <form action="" method="post" encType="multipart/form-data">
        <input type="file" name="documentation" onChange={onchangecb} />
      </form>
    </div>
  );
};

export default React.memo(UploadForm);
