export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_SSO_CLIENT_ID,
    authority: process.env.REACT_APP_SSO_AUTHORITY,
    redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_SSO_CLIENT_ID + '/.default'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_SSO_GRAPH_ME_ENDPOINT,
};
