import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import 'reset-css';
import 'react-loading-spinner';
import {TranslationsProvider} from '@vidiemme/react-i18n';
import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';

import {Navigation} from './navigation';
import {it} from './translations';
import reportWebVitals from './reportWebVitals';

import {msalConfig} from './utils/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

if (process.env.REACT_APP_SENTRY_ENABLED === true) {
  Sentry.init({
    release: process.env.npm_package_version,
    environment: process.env.REACT_APP_ENVIRONMENT,

    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === 'local' ? 1.0 : 0.2,
  });
}

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <TranslationsProvider
      params={{
        resources: {
          it: {
            translation: it,
          },
        },
      }}>
      <Navigation />
    </TranslationsProvider>
  </MsalProvider>,
  document.getElementById('root'),
);

if (process.env.REACT_APP_WEB_VITALS === 'true') {
  reportWebVitals();
}
