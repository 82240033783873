import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import UserStore from '../../stores/UserStore';

const Authenticated = props => {
  const userData = UserStore.getUserData();

  if (userData) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

export default React.memo(Authenticated);
