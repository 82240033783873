import React from 'react';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import './index.css';

const MenuElement = ({active, badge, url, label}) => {
  return (
    <li className={classnames('menu-element', {active})}>
      <Link to={url}>
        <span>{label}</span>
        {badge && <span className="badge">({badge})</span>}
      </Link>
    </li>
  );
};

export default React.memo(MenuElement);
