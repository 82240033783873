import React from 'react';
import classnames from 'classnames';
import './index.css';

const Btn = ({className, id, onClick, label}) => (
  <button className={classnames('btn-go', className)} id={id} onClick={onClick}>
    {label}
  </button>
);

export default React.memo(Btn);
