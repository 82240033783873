export const it = {
  gen: {
    go: 'go',
    notification: 'notification',
    success: 'SUCCESS',
    attention: 'ATTENTION',
    loginSso: 'Adidas Login',
    retry: 'Retry',
  },
  menu: {
    profile: 'profile',
    tools: 'tools',
    documents: 'documents',
    login: 'login',
    logout: 'logout',
  },
  login: {
    forgotpwd: 'forgot password?',
    username: 'USERNAME',
    password: 'PASSWORD',
    error: {
      failed: 'Login Errato!',
    },
    recovery: {
      'message-typemail': 'type your email and confirm to reset',
      success: 'An email has been sent to your account',
      expired:
        'Your account is expired. An email has been sent to your account',
      emptyMail: 'Enter your email in the username input',
    },
  },
  profile: {
    title: 'profile',
    bonus: 'Bonus Value',
  },
  tools: {
    title: 'TOOLS',
    assignedTool: 'ASSIGNED TOOL',
    department: 'DEPARTMENT:',
    date: 'DATE',
    ofAssignment: 'OF ASSIGNMENT',
    status: 'STATUS',
    event: {
      stolen: 'STOLEN',
      toaccept: 'TO BE ACCEPTED',
      assigned: 'ASSIGNED',
      lost: 'LOST',
      damaged: 'DAMAGED',
      returned: 'RETURNED',
      toreturn: 'TO BE RETURNED',
      policytoaccept: 'NEW POLICY',
      tobecollected: 'TO BE COLLECTED',
    },
  },
  toolDetails: {
    title: 'TOOL ACCEPTANCE',
    assignedTool: 'ASSIGNED TOOL',
    department: 'DEPARTMENT',
    date: 'DATE',
    ofAssignment: 'OF ASSIGNMENT',
    status: 'STATUS',
    toolDescription: 'DESCRIPTION',
    notes: 'NOTE',
    uploadFile: 'UPLOAD DOCUMENTATION',
    viewed: 'I read the document',
    agreement: 'I agree to the processing of personal information',
    notAgreement: "I don't agree to the processing of personal information",
    policy: 'DOWNLOAD POLICY',
    releasing: 'DOWNLOAD DISCLAIMER',
  },
  popupToolFooter: {
    title: 'MAP DEPARTMENT',
  },
  documents: {
    title: 'DOCUMENTS',
    assigned: 'NAME',
    type: 'TYPE',
    date: 'DATE',
    status: 'STATUS',
    accepted: 'ACCEPTED',
    notAccepted: 'NOT ACCEPTED',
    toAccept: 'TO BE ACCEPTED',
  },
  documentDetails: {
    title: 'DOCUMENTS',
    assigned: 'NAME',
    type: 'TYPE',
    typevalue: {
      disclaimer: 'DISCLAIMER',
      document: 'DOCUMENT',
      conflictofinterest: 'CONFLICT OF INTEREST',
      companycaruseauth: 'COMPANY CAR USE AUTHORIZATION',
      mixedcaruseauth: 'MIXED CAR USE AUTHORIZATION',
    },
    date: 'DATE',
    status: 'STATUS',
    download: {
      disclaimer: 'DOWNLOAD DISCLAIMER',
      document: 'DOWNLOAD DOCUMENT',
      conflictofinterest: 'DOWNLOAD DOCUMENT',
      mixedcaruseauth: 'DOWNLOAD DOCUMENT',
    },
    agreement: 'I AGREE',
    notAgreement: 'I DISAGREE',
    noConflict: 'I have no conflict of interest to report',
    conflict: 'I have the following conflict of interest of report',
    conflictFieldPlaceholder: 'Enter text here',
    viewed: {
      disclaimer: 'I read both documents',
      document: 'I read the document',
    },
    agreed: 'agreed',
    conflicted: 'conflicted',
    yes: 'yes',
    no: 'no',
    companycarbirthplace: 'BIRTHPLACE',
    companycarbirthdate: 'BIRTHDATE',
    companycarcity: 'CITY',
    companycaraddress: 'ADDRESS',
    companycarprintmessage:
      'ATTENTION: This letter must be printed and kept together with the documents inside the car. All fields must be filled out in order to print this document.',
  },
  error: {
    serverError: 'Server error!',
    error: 'ERROR',
  },
};
