import React from 'react';
import './index.css';

const PopupBody = ({title, classProp = '', children}) => (
  <div className="popcontainer">
    <div className="poptitle">{title}</div>
    <div className={'popbody ' + classProp ? classProp : ''}>{children}</div>
  </div>
);

export default PopupBody;
