import AppDispatcher from '../dispatcher';
import DocumentsConstants from '../constants/DocumentsConstants';

var DocumentsActions = {
  getDocuments: function (user) {
    AppDispatcher.dispatch({
      actionType: DocumentsConstants.GET_DOCUMENTS,
      user: user,
    });
  },
  getDocumentDetails: function (user, documentId) {
    AppDispatcher.dispatch({
      actionType: DocumentsConstants.GET_DOCUMENT_DETAILS,
      user: user,
      documentId: documentId,
    });
  },
  setAccepted: function (user, documentId) {
    AppDispatcher.dispatch({
      actionType: DocumentsConstants.SET_DOCUMENT_ACCEPTED,
      user: user,
      documentId: documentId,
    });
  },
  setDisclaimerAccepted: function (user, documentId, agreed) {
    AppDispatcher.dispatch({
      actionType: DocumentsConstants.SET_DISCLAIMER_ACCEPTED,
      user: user,
      documentId: documentId,
      agreed: agreed,
    });
  },
  setConflictOfInterestAccepted: function (
    user,
    documentId,
    conflict,
    text1,
    text2,
    text3,
  ) {
    AppDispatcher.dispatch({
      actionType: DocumentsConstants.SET_CONFLICT_OF_INTEREST_ACCEPTED,
      user: user,
      documentId: documentId,
      isConflict: conflict,
      text1,
      text2,
      text3,
    });
  },
};

export default DocumentsActions;
