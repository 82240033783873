import keyMirror from 'keymirror';

export default keyMirror({
  GET_TOOLS: null,
  GET_TOOL_DETAILS: null,
  SET_THEFT: null,
  SET_LOST: null,
  SET_DAMAGED: null,
  SET_ACCEPTED: null,
  SET_POLICYACCEPTED: null,
});
