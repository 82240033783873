import AppDispatcher from '../dispatcher';
import UserConstants from '../constants/UserConstants';

var UserActions = {
  login: function (token) {
    AppDispatcher.dispatch({
      actionType: UserConstants.LOGIN,
      token,
    });
  },
  logout: function () {
    AppDispatcher.dispatch({
      actionType: UserConstants.LOGOUT,
    });
  },
  recovery: function (user) {
    AppDispatcher.dispatch({
      actionType: UserConstants.RECOVERY,
      user: user,
    });
  },
  updateDocumentsToBeAccepted(docs) {
    AppDispatcher.dispatch({
      actionType: UserConstants.UPDATE_DOCUMENTS,
      docs: docs,
    });
  },
  decrementDocumentsToBeAccepted() {
    AppDispatcher.dispatch({
      actionType: UserConstants.DECREMENT_DOCUMENTS_COUNT,
    });
  },
};

export default UserActions;
