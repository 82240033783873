import Events from 'events';
import assign from 'object-assign';

import AppDispatcher from '../dispatcher';
import ErrorConstants from '../constants/ErrorConstants';

var EventEmitter = Events.EventEmitter;

const ERROR_401 = 'ERROR_401',
  ERROR_500 = 'ERROR_500',
  ERROR_UNKNOWN = 'ERROR_UNKNOWN';

var ErrorStore = assign({}, EventEmitter.prototype, {
  // ERROR 401
  _emit401Error(errors) {
    this.emit(ERROR_401, errors);
  },
  add401ErrorListener(callback) {
    this.on(ERROR_401, callback);
  },
  remove401ErrorListener(callback) {
    this.removeListener(ERROR_401, callback);
  },

  // ERROR 500
  _emit500Error(errors) {
    this.emit(ERROR_500, errors);
  },
  add500ErrorListener(callback) {
    this.on(ERROR_500, callback);
  },
  remove500ErrorListener(callback) {
    this.removeListener(ERROR_500, callback);
  },

  // ERROR UNKNOWN
  _emitUnknownError(errors) {
    this.emit(ERROR_UNKNOWN, errors);
  },
  addUnknownErrorListener(callback) {
    this.on(ERROR_UNKNOWN, callback);
  },
  removeUnknownErrorListener(callback) {
    this.removeListener(ERROR_UNKNOWN, callback);
  },
});

AppDispatcher.register(function (action) {
  if (action.actionType === ErrorConstants.GENERIC_ERROR) {
    ErrorStore._emit500Error(action.data);

    switch (action.data.status) {
      case '401':
        ErrorStore._emit401Error(action.data);
        break;
      case '500':
        ErrorStore._emit500Error(action.data);
        break;
      default:
        ErrorStore._emitUnknownError(action.data);
    }
  }
});

export default ErrorStore;
