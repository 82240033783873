import AppDispatcher from '../dispatcher';
import DrawerConstants from '../constants/DrawerConstants';

export default {
  toggle: function () {
    AppDispatcher.dispatch({
      actionType: DrawerConstants.TOGGLE,
    });
  },
  close: function () {
    AppDispatcher.dispatch({
      actionType: DrawerConstants.CLOSE,
    });
  },
};
