import React, {useEffect, useState, useCallback} from 'react';
import {withRouter} from 'react-router';
import {useTranslations} from '@vidiemme/react-i18n';
import {useMsal} from '@azure/msal-react';

import DrawerElement from './DrawerElement';
import './index.scss';

import DrawerStore from '../../../stores/DrawerStore';
import UserStore from '../../../stores/UserStore';
import UserActions from '../../../actions/UserActions';

function handleLogout(instance) {
  instance
    .logoutPopup()
    .catch(e => {
      console.error(e);
    })
    .then(UserActions.logout);
}

const Drawer = ({active}) => {
  const {instance} = useMsal();
  const {t} = useTranslations();

  const [open, setOpen] = useState(DrawerStore.getDrawerState());
  const [user, setUser] = useState(UserStore.getUserData());

  const _changeState = useCallback(state => {
    setOpen(state);
    setUser(UserStore.getUserData());
  }, []);

  const _onUserChange = useCallback(() => {
    setUser(UserStore.getUserData());
  }, []);

  useEffect(() => {
    DrawerStore.addChangeStateListener(_changeState);
    UserStore.addChangeListener(_onUserChange);

    return () => {
      DrawerStore.removeChangeStateListener(_changeState);
      UserStore.removeChangeListener(_onUserChange);
    };
  }, []);

  return (
    <div className={'left-drawer' + (open ? ' open' : '')}>
      <div className="container-fluid">
        <div className="row drawer-logged-user">
          <div className="col-xs-12 drawer-element">
            <span className="logged-user">{user?.name || ''}</span>
          </div>
        </div>

        <DrawerElement
          className="menu-element"
          active={active === 'PROFILE' ? true : false}
          label={t('menu.profile')}
          link="/profile"
        />
        <DrawerElement
          className="menu-element"
          active={active === 'TOOLS' ? true : false}
          label={t('menu.tools')}
          link="/tools"
        />
        <DrawerElement
          className="menu-element"
          active={active === 'DOCUMENTS' ? true : false}
          label={t('menu.documents')}
          link="/documents"
          badge={
            user?.documentsToBeAccepted > 0 ? user?.documentsToBeAccepted : 0
          }
        />

        <div className="row">
          <div
            className="col-xs-12 drawer-element logout-btn"
            onClick={() => handleLogout(instance)}>
            {t('menu.logout')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Drawer);
