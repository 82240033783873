import React, {useCallback, useEffect} from 'react';
import Popup from 'react-popup';
import {useTranslations} from '@vidiemme/react-i18n';
import {useHistory} from 'react-router-dom';

import './index.scss';

import PopupBody from '../PopupBody';
import ErrorStore from '../../../stores/ErrorStore';

import UserStore from '../../../stores/UserStore';
import DrawerActions from '../../../actions/DrawerActions';

const App = props => {
  const {t} = useTranslations();
  const {replace} = useHistory();

  const on401Error = useCallback(_data => {
    replace('/');
  }, []);

  const on500Error = useCallback(_data => {
    Popup.create({
      title: null,
      content: (
        <PopupBody title={t('error.error')}>{t('error.serverError')}</PopupBody>
      ),
      className: 'alert',
      buttons: {
        right: ['ok'],
      },
    });
  }, []);

  // @TODO: fix logout emitter
  const onLogout = useCallback(() => {
    window.location.reload();
    replace('/');
  }, []);

  useEffect(() => {
    DrawerActions.close();

    ErrorStore.add401ErrorListener(on401Error);
    ErrorStore.add500ErrorListener(on500Error);
    UserStore.addLogoutListener(onLogout);
    return () => {
      ErrorStore.remove401ErrorListener(on401Error);
      ErrorStore.remove500ErrorListener(on500Error);
      UserStore.removeLogoutListener(onLogout);
    };
  }, []);

  return (
    <div>
      {props.children}
      <Popup />
    </div>
  );
};

export default App;
